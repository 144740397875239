@media print {
    @page {
        size: 210mm 297mm; /* portrait */
        /* you can also specify margins here: */
        /* margin: 25mm; */
        /* margin-right: 45mm; for compatibility with both A4 and Letter */
        /* display: flex;
        flex-wrap: wrap; */
    }
    div {
        page-break-inside: avoid;
    }
}

.printer-page-item {
    margin-left: 0mm;
    margin-right: 0mm;
    margin-top: 0mm;
    page-break-inside: avoid;
    display: inline-grid;
    margin-bottom: 0mm;
}