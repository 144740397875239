.center{
    alignment: center;
}

.card-wrapper {
    margin: auto;
}

.container{
  display: flex; /* or inline-flex */
  align-content: space-around;
  justify-content: space-around;
  flex-wrap: wrap;
}

.editorPanelContainer{
    align-content: space-around;
    flex-grow: 1;
    /*margin: 2%;*/
    /*justify-content: space-around;*/
    flex-flow: column wrap;
    /*border: 2px solid gray;*/
    /*border-left: 2px solid gray;*/
    /*border-radius: 10px;*/
    min-width: auto;
    /*background-color: whitesmoke;*/
    /*flex-grow: 4;*/
    width: 500px;
    /* max-width: 500px; */
    /*line-height: 1.75em;*/
}

.editor-field{
    display: flex;
    flex-direction: row;
    line-height: 1.25em;
}

.editor_background-image{
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    border-color:darkgray;
    display: flex;
    flex-direction: column;
    line-height: 1.25em;
    background-color: #9ed3ff;
    width: 100%;
    box-sizing: border-box;
    padding-right: 7px;
}

.editor-label{
    font-size: 10pt;
    font-weight: bold;
    padding: 5px;
    margin-top: auto;
    margin-bottom: auto;
}

.editor-text{
    font-size: 10pt;
    padding: 5px;
    margin-top: auto;
    margin-bottom: auto;
}

.editor-input{
    flex-grow: 1;
    font-size: 10pt;
    margin-top: auto;
    margin-bottom: auto;
}

.editor-icon{
    margin-top: auto;
    margin-bottom: auto;
}

.image-selector{
    flex-grow: 1;
    font-size: 10pt;
    margin-top: auto;
    margin-bottom: auto;
    min-width: 91px;
    /* z-index: 2; */
}

.editor-selector{
    flex-grow: 1;
    font-size: 10pt;
    margin-top: auto;
    margin-bottom: auto;
    height: 1.5em;
    /*#padding: 1px;
    #border-radius: 5px;*/
}

.select__item{
    height:20px;
}

.grid{
    background-size: 5mm 5mm;
    background-image: linear-gradient(to right, grey 1px, transparent 1px),
            linear-gradient(to bottom, grey 1px, transparent 1px);
    min-height: 100mm;
    /* min-width: 100mm; */
    position: relative;
    display: flex;
    flex-direction: column;
    box-shadow: 6px 6px #888888;
    margin: 0em 1em 1em 1em;
    border: medium solid #888888;
    flex-grow: 2;
    box-sizing: border-box;
}

.rotate-90{
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: 100% 100%;
    -moz-transform-origin: 100% 100%;
    -ms-transform-origin: 100% 100%;
    -o-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
}

.zoom-slider {
    position: absolute;
    bottom: 0;
    width: 99%; 
    outline: none; 
    opacity: 0.7; 
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
    box-sizing: border-box;
  }

.zoom-slider:hover {
    opacity: 1;
}

.help-text {
    margin-top: .5em;
    background-color: lightyellow;
    border-style: solid;
    border-width: 1px;
    border-color: lightgray;
    font-size: 1em;
    font-style: italic;
}

.component-tab {
    background-color: lightgray;
    margin-left: 3px;
    color: white;
    font-weight: bold;
    width: 100%;
    padding: 10px 10px 2px 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    text-align: center;
}

.component-tab:hover {
    /* color: gray; */
    cursor: pointer;
}

.tab-selected {
    background-color: #84a2ce;
}

.customize-back-button {
    margin: auto !important;
    padding: 10px;
}