.project_list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-evenly;
}

.project_title {
  position: relative;
  font-size: 2em;
  padding: .5em;
  margin: auto;
  font-weight: bold;
  color: black;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  /* font-family: 'Franklin Gothic Medium'; */
  font-family: monospace;
}

.project-name {
  margin: auto;
  flex-grow: 1;
}

.project_title:hover {
  color: ghostwhite;
}

.project_field {
    font-weight: bold;
    line-height: 2em;
    align-content: left;
    margin: auto;
    font-size: 1em;
}

.collection-label {
  margin-right: 10px;
}

.project_fields {
  display: flex;
  flex-direction:row;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.project_editor {
    padding: 1em;
    display: flex;
    flex-direction: column;
}

.project_text_area{
    height: 200px;
    width: 500px;
}

.text_area {
    display: flex;
    flex-direction: column;
}

.project_input {
  font-size: 1em;
}

.deck {
  display: flex;
  flex-direction: column;
}

.add-collection {
  background-color: mediumblue;
  color: white;
  height: 35px;
  width: 120px;
  margin: 5px
}

.accordion-collection-header {
  text-align: center;
  width:100%;
  margin-top: 0em;
  margin-bottom: 0;
}

.collection-header {
  /* text-align: center;
  width:100%;
  margin-top: 1em;
  margin-bottom: 0; */
  /* display: flex;
  flex-direction: row; */

  text-align: center;
  width: 100%;
  margin-top: 1em;
  margin-left: auto;
  padding-top: .5em;
  padding-bottom: .5em;
  margin-bottom: 0;
  background-image: linear-gradient(to bottom, white, #84a2ce);
  margin-right: auto;
}

.component_list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.width_100 {
  width: 100%;
}

.card_preview {
  margin: 0; 
}

.create-card{
  margin-top: auto;
  margin-bottom: auto;
}

.game-component-wrapper{
  position: relative;
  margin: .5em;
  box-sizing: border-box;
  transition: transform 75ms ease-in-out 30ms;
  transition: margin 75ms ease-in-out 30ms;
  color: black;
  text-decoration: none;
}

.game-component-wrapper:hover{
   margin-top: 0em;
   margin-bottom: 1em;
  cursor: pointer;
}

.printer-icon{
  margin-left: 1em;
  /* font-size: 12pt; */
  /* color: gray; */
}

/* .printer-icon:hover{
  color: blue;
  cursor: pointer;
} */

.editor-wrapper{
  height: 400px;
  min-height: 400px;
}

.project-list{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: space-around;
}

.project-square a{
  text-decoration: none;
  color: none;
}

.project-thumbnail a {
  text-decoration: none;
  color: black;
}

.project-thumbnail{
  margin: 10px 10px;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
}

.add-project-button{
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  margin-top: 1em;
  margin-bottom: .5em;
}

.add-project-outter-square {
  position: relative;
  color: black;
  text-align: center;
  width: 165px;
  height: 50px;
  border-radius: 80px;
  box-sizing: border-box;
  background-image: linear-gradient(to right bottom, #fdefd5, rgb(246, 173, 4));
  border-width: 2px;
  border-style: solid;
  border-color: black;
}

.add-project-outter-square:hover {
  /* color: #004495; */
  color: ghostwhite;
  border-color: #004495;
}

.add-project-button:hover{
  cursor: pointer;
  border-radius: 80px;
}

.project:hover{
  cursor: pointer;
}

.project{
    position: relative;
    background-color:white;
    background-image: linear-gradient(to right bottom, white, rgb(246, 173, 4));
    min-width: 200px;
    /* min-height:100px; */
    border: 2px solid black;
    border-radius: 10px;
    box-sizing: content-box;
    margin-left: auto;
    margin-right: auto;
    color: gray;
    text-align: center;
    display: flex;
    flex-direction: row;
    box-shadow: 6px 6px 0 0 darkgray;
    /* flex-wrap: wrap; */
    flex-grow: 2;
    /* overflow: hidden; */
}

.project-square-contents{
  position: relative;
  display: flex;
  flex-grow: 0;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  flex-wrap: wrap;
}

.delete-project{
  display: flex;
  position: relative;
  width: 45px;
  border-radius: 5px;
  margin: 5px 5px 5px 0px;
  flex-grow: 1;
  color: black;
  background-color: #ec0b0b;
}

.delete-project:hover{
  cursor: pointer;
  color: lightgray;
}

.project-trash-icon{
  margin: auto;
  font-size: 16pt;
  align-content: center;
}

.float{
	position:fixed;
	bottom:0px;
  z-index: 10;
}

.float-right{
	position:fixed;
	bottom:0px;
  right:40px;
  z-index: 10;
}

.float-left{
	position:fixed;
	bottom:0px;
  left:40px;
  z-index: 10;
}

.card-style-selection-wrapper:hover{
  cursor: pointer;
}

.close{
  display:none;
  height: 0;
}

.open{
  /* transition: height 2s;
  -moz-transition: height 2s; 
  -webkit-transition: height 2s;  */
  height:auto;
} 


.expand-wrapper{
  overflow: hidden;
  transition: height 5s;
  -webkit-transition: height 2s;
}


.add-collection-icon{
  color: white; 
  text-align: center;
  box-sizing: content-box;
  font-size: 1.5em; 
  transform: translate(-50%, -50%);
  position: relative;
  top: 50%;
  left: 50%;
}

.add-collection-button{
  border-radius: 50%; 
  margin-top: .75em;
  margin-left: auto;
  margin-right: auto;
  height: 50px; 
  width: 50px;
  background-color: #004496;
}

.add-collection-button:hover{
  cursor: pointer;
  background-color: lightgray;
}

.add_overlay{
  color: gray;
}

.add_overlay:hover{
  color: darkblue;
  border-color: darkblue;
  border-style: solid;
  border-width: 3px;
}

.print-button{
  background-color: #fab600 !important;
  color: white;
  height: 35px;
  width: 120px;
  margin-left: 10px;
}

.settings-icon {
  padding: 5px;
}