.template__components-table{
    /* margin-top: 1em;
    margin-bottom: 1em; */
    margin: 1em;
    padding-bottom: 1em;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-content: space-between;
    background-color: ghostwhite;
    border: 2px solid gray;
    border-radius: 10px;
}

@media screen and (max-width: 650px) {
    .template__components-table {
        margin-right: 0em;
        margin-left: 0em;
    }
  }

.icon-cell {
    text-align: center;
    color: gray;
    font-size: 1.25em;
    margin: auto;
    align-content: space-between
}

.preview-icon {
    color: gray;
    font-size: 1.25em;
}

.preview-icon:hover{
    color: blue;
    cursor: pointer;
}

.add-template-component-icon{
    color: white; 
    text-align: center;
    box-sizing: content-box;
    font-size: 1em; 
    transform: translate(-50%, -50%);
    position: relative;
    top: 50%;
    left: 50%;
}

.add-template-component-button{
    position: relative;
    border-radius: 50%; 
    margin-top: 1em;
    margin-left: auto;
    margin-right: auto;
    height: 26px; 
    width: 26px;
    background-color: #024693;
    line-height: 1em;
}

.add-template-component-button:hover{
    cursor: pointer;
    background-color: lightgray;
}
  
.delete-row-icon:hover {
    cursor: pointer;
    color: red;
} 

.table_input-field {
    width: 100%;
    height: 100%;
}

.preview-component{
    /* float: right;
    right: 0;
    top: 0;
    position: absolute; */
    padding: 0% 10% 2% 10%;
    background-color: lightblue;
    /* margin: 1em auto 1em auto; */
    border-radius: 8px 8px;
    border: solid 2px black;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.preview-text {
    text-align: center;
    color: gray;
    font-size: 1.5em;
    font-weight: bold;
    font-style: italic;
    line-height: 1.5em;
}

.close-preview-icon {
    color: black;
    font-size: 1.25em;
    position: absolute;
    float: right;
    right: 5%;
    top: 3%;
}

.close-preview-icon:hover {
    cursor: pointer;
    color: gray;
}

.preview-wrapper-front-back {
    display: flex;
    flex-direction: row;
    margin: auto;
    flex-wrap: wrap;
    flex-grow: 1;
    align-content: space-around;
    width: 100%
}

.preview-wrapper {
    display: flex;
    flex-direction: column;
    margin: auto;
    flex-grow: 1;
}