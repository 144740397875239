.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-image: linear-gradient(to right bottom, whitesmoke, white);
}

.header{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-bottom: #004496 solid 2px;
  background-image: linear-gradient(to bottom right, white, #004496);
}

.menu {
  align-self: flex-end;
  min-width: 60px; 
  margin: auto; 
}

.menu-items {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 0em;
  padding: 1.75em 1.75em .5em 1.75em;
  list-style-type: none;
  flex-wrap: wrap;
}

.logo-url {
  padding-top: .5em;
  margin: auto;
  max-height: 6em;
  max-width: 100%;
  height: auto;
}

.logo {
  max-height: 6em;
  max-width: 100%;
  height: auto;
}

.main {
  flex-grow: 1;
}

.footer {
  margin-top: 1em;
  padding: 0.5em 0;
  flex: 0;
  text-align: center;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
}

.footer_links {
  margin: auto;
  display: flex;
  flex-direction: row;
}

.footer_link {
  margin: .25em;
}

.twitter-icon{
  font-size: 2em;
  color: #38A1F3;
  margin-left: .25em;
  margin-right: .25em;
}

.youtube-icon{
  font-size: 2em;
  color: red;
  margin-left: .25em;
  margin-right: .25em;
}

.facebook-icon{
  font-size: 2em;
  color: #3b5998;
  margin-left: .25em;
  margin-right: .25em;
}

.follow-text{
  margin-top: auto;
  margin-bottom: auto;
}

.follow-us{
  display: flex;
  flex-direction: row;
  margin: auto;
}

.menu-item {
  text-decoration: none;
  margin: 1em;
  font-size: 1.25em;
  color: white;
  font-weight: bold;
  line-height: 1.25em;
}

.menu-item:hover {
  color: #004297;
  border-bottom: 2px solid #004297;
}

.form {
  margin-top: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.button {
  text-align: center;
  flex-wrap: wrap;
  max-width: 200px;
  border-radius: 5px;
  margin: auto;
  box-shadow: none;
  border: none;
  cursor: pointer;
  color: white;
  background-color:#004297;
  margin-right: 5px;
}

.delete-button{
  background-color: #ad4040;
  color: white;
  height: 35px;
  width: 120px;
  margin: 5px
}

.confirm-button{
  background-color: #ad4040;
  color: white;
  height: 35px;
  width: 120px;
  margin: 5px
}


.form-item {
  max-width: 100%;
  text-align: center;
  margin-top: .5em;
  margin-bottom: .5em;
  margin-left: auto;
  margin-right: auto;
  flex-wrap: wrap;
  padding: .5em;
  box-shadow: none;
  min-width: 100px;
}

.form_text {
  font-size: 13px;
}

.input_field {
  text-align: left;
  min-width: 200px;
}

.title {
  text-shadow: 2px 1px 1px grey;
  font-size: 4em;
  color: #004297;
  font-style: italic;
  text-align: center;
  font-weight: 600;
  font-family: initial;

}

.sub-title {
  font-size: 12pt;
  color: #dd0000;
  font-style: italic;
  text-align: center;
  font-weight: bold;
  padding-bottom: 1em;
  font-family: monospace;
}

.public-DraftStyleDefault-block {
  margin: 0 0;
}

.DraftEditor-editorContainer {
  background-color: white;
}

.accordion__title {
  background-color: #f4f4f4;
  color: #444;
  cursor: pointer;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  text-align: left;
  border: none;
  box-sizing: border-box;
  position: relative;
}

.save-button{
  background-color: #004495;
  color: white;
  height: 35px;
  width: 120px;
  /* margin: 5px; */
  margin: 5px auto 0 5px;
  /* right: 0px; */
  /* float: left; */
}

.export-button{
  background-color: #098a09;
  color: white;
  height: 35px;
  width: 120px;
  margin: 5px auto auto auto;
}

.download-button{
  background-color: #098a09;
  color: white;
  height: 35px;
  width: 120px;
  margin: auto;
}


.cancel-button{
  background-color: #ad4040;
  color: white;
  height: 35px;
  width: 120px;
  margin: 5px 5px 0 auto;
  /* margin: 5px; */
  /* float: right; */
}

.delete-modal {
  z-index: 10000;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: space-between;
  margin: auto;
}

.delete-modal-text {
  font-size: 12pt;
  text-align: center;
}

.button-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.cancel-modal-button{
  background-color: #ad4040;
  color: white;
  height: 35px;
  width: 120px;
  margin: auto;
  margin-top: 2px;
  margin-bottom: 2px;
}

.confirm-modal-button{
  background-color: #004495;
  color: white;
  height: 35px;
  width: 120px;
  margin: auto;
  margin-top: 2px;
  margin-bottom: 2px;
}

h5 {
  margin: 0;
}

.center {
  text-align: center;
}

.center-transform {
  position: absolute;
  left: 50%; 
  top: 50%; 
  transform: translate(-50%, -50%);
  width: 99%;
  height: 99%;
}

.google-icon {
  font-size: 3em;
  color: #e60000;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
}

.trash-icon {
  color: gray;
  position: relative;
  margin-left: 90%;
  margin-top: 2em;
}

.trash-icon:hover {
  cursor: pointer;
  color: red;
}

.project-delete {

}

.center-text {
  font-size: 20pt;
  text-align: center;
  color: inherit;
  box-sizing: content-box;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.delete-icon {
    color: red;
    margin-top: auto;
    margin-bottom: auto;
}

.delete-icon:hover {
  cursor: pointer;
}

.add-outter-circle {
  border: inherit;
  background-color: inherit;
  border-radius: 100%;
  width: 70px;
  height: 70px;
  margin: auto;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.rdw-editor-toolbar{
  background-color: #cdd9e4;
  border-bottom: 1px solid darkgray;
}

.rdw-editor-wrapper{
  border: 2px solid darkgray;
  border-radius: 5px;
}

.public-DraftEditor-content {
  height: 50px;
}

.middle {
    vertical-align: middle;
    margin-top: .25em;
}

.edit-link {
  margin: auto;
  text-decoration: none;
  color: black;
}

.edit-icon:hover{
  color: darkblue;
}

.trash-card-icon:hover{
  color: red;
}

.duplicate-card-icon:hover{
  color: darkblue;
}

input:disabled{
  background-color:lightgray;
}

img {max-width:100%;}

.modal {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}

.close-modal {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close-modal:hover,
.close-modal:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}