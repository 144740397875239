.large-font{
    font-size: 2em;
    line-height: 1em;
    margin: 1em auto;
}
 
.home-presentation {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.two-tile {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    margin: auto;
}

.home-presentation-tile {
    min-width: 300px;
    width: 50%;
    margin: 0em auto 0em auto;
    flex-grow: 1;
    padding: 1em;
    box-sizing: border-box;
}

.text-body {
    line-height: 1.5em;
    font-family: sans-serif; 
}