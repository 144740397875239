.middle {
  vertical-align: middle;
}

.slidecontainer {
  flex-grow: 1;
  margin: auto;
  position: relative;
  /* width: 10px; */
}

/* The slider itself */
.editor-slider {
  position: relative;
  margin: auto;
  /* margin-top: 5px; */
  /*-webkit-appearance: none;  !* Override default CSS styles *!*/
  /*appearance: none;*/
  /* width: 100%; */
  height: 25px; /* Specified height */
  /*background: white; !* Grey background *!*/
  outline: none; /* Remove outline */
  opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: 0.2s; /* 0.2 seconds transition on hover */
  transition: opacity 0.2s;
}

/* Mouse-over effects */
.editor-slider:hover {
  opacity: 1; /* Fully shown on mouse-over */
}

.center-icon {
  text-align: center;
  box-sizing: content-box;
}

.add-section-button:hover {
  cursor: pointer;
  color: lightgray;
}

.remove-back-button:hover {
  cursor: pointer;
  color: lightgray;
}


.add-section-button {
  font-size: 1em;
  color: #004496;
  text-align: center;
  margin-top: 0.75em;
  margin-bottom: 0.75em;
  margin-left: auto;
  margin-right: auto;
}

.remove-back-button {
  font-size: 1em;
  color: #960000;
  text-align: center;
  margin-top: 0.75em;
  margin-bottom: 0.75em;
  margin-left: auto;
  margin-right: auto;
}

.copy_section {
  display: inline-block;
  width: 24px;
  position: absolute;
  top: 50%;
  right: 20px;
  margin-top: -9px;
}

.copy_section:hover {
  color: blue;
}

.drag-handle{
  margin-left: 5px;
  margin-right: 5px;
  color: darkgray;
}

.accordion_title-content {
  display: flex;
  flex-direction: row;
}

.accordion_title-text {
  margin-left: 2px
}

.accordion_card-title-text {
  margin-left: 38px;
}