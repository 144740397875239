.dropzone-area {
    width: 100%;
    height: 200;
    border-width: 2;
    border-color: 'gray';
    border-style: 'solid';
    border-radius: 5;
}

.image-preview-wrapper {
    position: relative;
    margin: auto;
    margin-top: .5em;
    margin-bottom: .5em;
    max-width: 210px;
    word-break: break-all;
}

.image-preview {
    width: 200px;
    height: 200px;
    margin: .5em;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.images-preview {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: space-between;
    align-items: flex-start;
}

.file-upload{
    width: 100%;
    height: 200px;
    border-width: 2px;
    border-color: gray;
    border-style: solid;
    border-radius: 5px;
    margin: 1em;
  }

.file-messages{
    align-content: flex-start;
}