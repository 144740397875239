
.permissions {
    display: flex;
    flex-direction: column;
}

.permissions-header {
    display: flex;
    flex-direction: row;
    align-content: center;
    text-align: center;
    margin: auto;
    vertical-align: middle;
}

.permissions-icon {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 10px;
    margin-right: 10px;
}