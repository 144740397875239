.component {
    box-sizing: border-box;
    -webkit-print-color-adjust: exact;
    position: relative;
    outline:none;
    margin: auto;
    /* font-family: none; */
    /* font-style: none; */
}

.section{
    padding:2px;
    box-sizing:border-box;
    width: 100%;
    height: 100%;
    position:absolute;
}

.highlight-border{
    box-shadow: 0px 0px 3px 1px darkblue;
    background-color: lightblue;
}

.editable-section {

}

.editable-section:hover {
    /*box-sizing: border-box;*/
    /*border: 2px solid red;*/
    /*border-radius: 5px;*/
    box-shadow: 0px 0px 3px 1px darkblue;
    background-color: lightblue;
}

.section p {
    margin-top: 0em;
    margin-bottom: 0em;
}

.section li{
    margin-top: 0em;
    margin-left: 0em;
    margin-bottom: 0em;
    margin-right: 0em;
    padding-left: 0em;
}

.section ul{
    margin-top: 0em;
    margin-left: 1em;
    margin-bottom: 0em;
    margin-right: 0em;
    padding-left: 0em;
}

.section ol{
    margin-top: 0em;
    margin-left: 1em;
    margin-bottom: 0em;
    margin-right: 0em;
    padding-left: 0em;
}

.section blockquote{
    color: #666;
    font-family: Hoefler Text,Georgia,serif;
    font-style: italic;
}
.selected{
    box-shadow: 0px 0px 3px 1px darkblue;
    /* background-color: rgba(173, 216, 230, .6); */
    background-color: lightblue;
}

.blue-dot {
    /* background-image: radial-gradient(lightblue, blue); */
    background-color: blue;
    max-height: 8px;
    max-width: 8px;
    border-radius: 50%;
    cursor: grab;
    z-index: 202;
  }