.sectionEditor {
    cursor: default;
}

.variables {
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2);
    padding: 21px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.variable {
    padding: 10px;
    text-align: center;
    border: 5px;
	border-radius: 3px;
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
	background: rgba(212, 212, 212, 0.432);
	transition: background-color 0.1s ease-in-out;
}